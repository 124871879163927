import domReady from '@roots/sage/client/dom-ready';


//import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import "./modules/alpinejs.js";
import "./modules/tippy.js";
import "./modules/select2.js";
import "./modules/swiper.js";


//setup
import "@scripts/components/devices.js";
import "@scripts/components/google-maps.js";

//components
import "@scripts/components/checkbox.js";
import "@scripts/components/dropdown.js";
import "@scripts/components/radio.js";
import "@scripts/components/modal.js";
import "@scripts/components/text-dropdown.js";
import "@scripts/components/dropdown-filter.js";
import "@scripts/components/carousel.js";
import "@scripts/components/videojs.js";
import "@scripts/components/checkout.js";

// composites
import "@scripts/components/navbar.js";
import "@scripts/components/form-tabs.js";
import "@scripts/components/product-filter.js";
import "@scripts/components/seminar-block.js";


// pages
import "@scripts/components/cart.js";
import "@scripts/components/wishlist.js";
import "@scripts/components/search.js";
import "@scripts/components/speaker.js";

//Swiper.JS
import "@scripts/components/testimonial-slider.js";
import "@scripts/components/product-slider.js";
import "@scripts/components/speaker-slider.js";
import "@scripts/components/accordion-sliper.js";
import carousel from "@scripts/components/carousel.js";

/**
 * app.main
**/
const main = async (err) => {
    if (err) {
        // handle hmr errors
        console.error(err);
    }





    window.isPc = window.innerWidth > 1024;
    window.isTablet = window.innerWidth > 759 && window.innerWidth <= 1024;
    window.isMobile = window.innerWidth < 760;

    window.updateSelect2 = function(e) {
        $(e).select2()
    }

    window.getSchool = function (name, handler) {
        jQuery.ajax({
            type: "POST",
            dataType: "json",
            url: window.app.ajax_url,
            data: {
                'action': 'get_school_infos',
                'name': name,
            },
            success: function (response) {
                if (response.success) {
                    handler(response)
                    return;
                }

                console.error(response.message);
            },
            error: function (response) {
                console.error(response.message);
            },
            statusCode: {
                404: function () {
                    console.error("school not found");
                },
                500: function () {
                    console.error("invalid query");
                }
            }
        })
    }
};

document.addEventListener('alpine:init', () => {
    Alpine.data('dropdown', () => ({
        open: false,

        toggle() {
            this.open = ! this.open
        }
    }))
})

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
domReady(main);
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
