function handleBillingMembers(form) {
    console.log("handleBillingMembers")
    const member = form.find('select[name="billing_member"]')
    const billingMemberWrapper = member.closest('.form-row')
    const company = form.find('input[name="billing_company"]')
    const school = form.find('select[name="billing_school"]')
    const submit = form.find('button[type="submit"], input[type="submit"]')

    const memberCases= JSON.parse(billingMemberWrapper.data('memberStatus').replace(/'(.+?)':(.+?)/g, '"$1":$2'))

    const checkoutContainer = form.closest('[data-checkout-members-only]')
    const onlyMembers = checkoutContainer.length
        ? parseInt(checkoutContainer.data('checkoutMembersOnly')) === 1 : false


    function changeMemberStatus(status) {
        const isMember = memberCases[status];

        if(isMember === true) {
            submit.prop('disabled', false)
            company.closest('.form-row').hide()
            school.closest('.form-row').show()
        } else if(isMember === false && onlyMembers !== true) {
            submit.prop('disabled', false)
            company.closest('.form-row').show()
            school.closest('.form-row').hide()
        } else {
            submit.prop('disabled', true)
            company.closest('.form-row').hide()
            school.closest('.form-row').hide()
        }
    }


    member.find("> option").each(function() {
        const element = jQuery(this)
        const isMember = memberCases[this.value];
        element.prop('data-member', isMember ? '1' : '0')
        element.data('member', isMember ? '1' : '0')

        if(onlyMembers && !isMember) {
            element.disabled = true
            element.prop('disabled', true)
            if(element.prop("selected")) {
                //element.prop('selected', false)
            }
        }
    });

    member.select2()

    changeMemberStatus(member.val())

    member.on("select2:select", function(e) {
        const element = jQuery(this)
        changeMemberStatus(element.val())
    })

}

$(document).ready(function () {

    const form = $('.woocommerce-billing-fields, .woocommerce-address-fields').closest("form");

    if(form.length) {
        console.log("form found", form)
        handleBillingMembers(form);
    }


});